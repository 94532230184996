<div class="flex align-center mt-30 gap-20 wrap" style="justify-content: center">
    <a href="https://discord.gg/M5WuF85NWz" target="_blank" class="flex align-center">
        <div class="contact-icon flex align-center justify-center" style="background-color: #5865f2">
            <img src="discord.svg" alt="Discord Logo" width="40px" height="40px">
        </div>
        <div class="contact-text" style="background-color: #3e48b7;">Discord</div>
    </a>
    <a href="https://www.youtube.com/channel/UCUV02i7ISC193xkvwrO1-ew" target="_blank" class="flex align-center">
        <img src="youtube.png" alt="YouTube Logo" class="contact-icon" >
        <div class="contact-text" style="background-color: #DD0000;">YouTube</div>
    </a>
</div>

<style>
    .contact-icon {
        width: 50px;
        height: 50px;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
    }

    .contact-text {
        padding: 8px;
        padding-top: 9px;
        padding-bottom: 7px;
        color: white;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
        width: 100px;
        height: 50px;
        text-align: center;
        font-size: 1rem;
        font-family: Epilogue;
        line-height: 2.1rem;
    }

    a {
        text-decoration: none;
    }
</style>