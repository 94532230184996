    <script lang="ts">
    import { onDestroy, onMount } from "svelte";
    import Page from "../components/page.svelte";
    import Social from "../components/social.svelte";
    import insights from '../services/insights.js';
    import { smallScreen } from "../util/screen.js";
    import Expander from '../components/expander.svelte';
    import External from '../components/external.svelte';

    const studioUrl = "https://www.virtualmaker.dev";

    let signupClosed = true;

    function onSignUpClicked() {
        insights.trackEvent('BetaSignUpClicked');
        window.location.href = '/edit';
    }

    $: pagePosition = signupClosed ? "relative" : "fixed";

    function updateScroll() {
        const sections = document.querySelectorAll('section');
        for (const section of sections) {
            if (section.offsetTop - window.scrollY < window.innerHeight * 4 / 5) {
                const child = section.children[0];
                if (child instanceof HTMLElement) {
                    if (child.getAnimations) {
                        if (child.getAnimations().length > 0) {
                            const animation = child.getAnimations()[0];
                            if (animation.currentTime === 0) {
                                animation.onfinish = () => updateScroll();
                                animation.play();
                            }
                        }
                    }
                }
            }
        }
    }

    window.addEventListener('scroll', updateScroll);
    onMount(() => setTimeout(() => updateScroll()));

    let expanded = 0;
    function onExpand(e: number) {
        expanded = e;
    }

    function onCollapse() {
        expanded = -1;
    }

    let faqExpanded = -1;
    function onExpandFaq(e: number) {
        faqExpanded = e;
    }

    function onCollapseFaq() {
        faqExpanded = -1;
    }

    onDestroy(() => {
        window.removeEventListener('scroll', updateScroll);
    });
</script>

<Page>
    <div style="position: {pagePosition}" class:sz-24={!$smallScreen} class="flex col w-100 align-center mt-30">
        <section id="what" class="flex align-start v-vw" class:pt-60={!$smallScreen} class:wrap={$smallScreen} style="min-height: 80vh">
            <div class="flex rounded-20 mt-30 shadow">
                <img src="splash.png" alt="Virtual Maker Cross Platform Support">
            </div>

            <div class="flex col align-center">
                <div class="rounded-20 p-30" class:text-center={$smallScreen} class:text-left={!$smallScreen} style="z-index: 1; color: white;">
                    <p class="bold" class:sz-48={!$smallScreen} class:sz-36={$smallScreen} style="margin: 0px; color: rgb(10, 43, 75)">Make interactive<br>3D and VR scenes<br>in your browser</p>
                    <div class="flex col gap-10 w-100" class:align-center={$smallScreen}>
                        <button class="primary-button action-button flex col align-center" disabled={$smallScreen} style="width: 220px" on:click={onSignUpClicked}>
                            Launch Editor
                            {#if $smallScreen}
                                <span class="sz-16">(Requires PC or Mac)</span>
                            {/if}
                        </button>
                        <a href="/demos" class="primary-button action-button"  style="width: 220px; text-decoration: none">Try Demos</a>
                    </div>
                    <p class="sz-20 mt-30 ml-10 bold" style="color: rgb(10, 43, 75)">✓ No signup required</p>
                    <p class="sz-20 mt-5 ml-10 bold" style="color: rgb(10, 43, 75)">✓ Unlimited projects</p>
                    <p class="sz-20 mt-5 ml-10 bold" style="color: rgb(10, 43, 75)">✓ Free hosting</p>
                    <p class="sz-20 mt-5 ml-10 bold" style="color: rgb(10, 43, 75)">✓ 1GB Storage</p>
                </div>
            </div>
        </section>

        <div class="w-100 flex justify-center" style="background: linear-gradient(120.67deg, #202020 11.3%, #222222 80.51%);">
            <div class="max-page-width w-100" class:mt-120={!$smallScreen} class:mb-120={!$smallScreen}>
                <div class="w-100 flex justify-center relative border border-gray shadow" style="padding-bottom: 56%">
                    <iframe width="100%" height="100%" class="absolute" src="https://www.youtube-nocookie.com/embed/X8yQsO3f-dc" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
            </div>
        </div>

        <section id="" class="grad" style="background-image: linear-gradient(130deg, rgba(17, 95, 196, 0.6) 25%, rgba(73, 0, 119, 0.56) 100%); color: white;">
            <div class:pt-60={!$smallScreen} class:pb-120={!$smallScreen} class="content col" style="background: transparent">
                <p class:section-title={!$smallScreen} class:section-title-small={$smallScreen}>A COMPLETE SOLUTION FOR YOUR 3D PROJECT</p>
                <p class="bold">Virtual Maker removes the barriers to building and hosting a 3D / VR web app, so you can focus on designing a higher quality experience.</p>

                <Expander expanded={expanded === 0} on:expand={() => onExpand(0)} on:collapse={onCollapse}>
                    <p slot="header" class="bold">Intuitive 3D Web Editor</p>
                    <div slot="content" class="flex w-100 align-center" class:wrap={$smallScreen}>
                        <img class="p-20 noshrink" class:w-50={!$smallScreen} src="createbg.png" alt="editor">
                        <div>
                            <p>Build scenes in a fun and intuitive ways. Add <b>animations</b> and <b>interactions</b> easily through drop-down menus.</p>
                            <p><b>No programming is required</b>, but if you know how to code, take advantage of integrated text editor our powerful JavaScript API.</p>
                            {#if !$smallScreen}
                                <button class="primary-button action-button flex col align-center" style="width: 220px" on:click={onSignUpClicked}>
                                    Launch Editor
                                </button>
                            {/if}
                        </div>
                    </div>
                </Expander>
                <Expander expanded={expanded === 1} on:expand={() => onExpand(1)} on:collapse={onCollapse}>
                    <p slot="header" class="bold">Instinctual Interaction Model</p>
                    <div slot="content" class="flex w-100 align-center" class:wrap={$smallScreen}>
                        <!-- svelte-ignore a11y-media-has-caption -->
                        <div class:w-40={!$smallScreen} class:w-100={$smallScreen} class="p-20 noshrink">
                            <video autoplay loop muted controls>
                                <source src="grab.mp4" type="video/mp4">
                            </video>
                        </div>
                        <p>Virtual Maker provides configurable interaction models to support gaze, hands, controllers, touch screens, mice and keyboards. Virtual Maker uses intelligent hardware detection to automatically select the best interaction model for each user device.</p>
                    </div>
                </Expander>
                <Expander expanded={expanded === 2} on:expand={() => onExpand(2)} on:collapse={onCollapse}>
                    <p slot="header" class="bold">Cross-Platform Support</p>
                    <div slot="content">
                        <p>Virtual Maker is built on the web, so it supports any device with a modern browser on PC, Mac, iOS, Android, and a variety of VR devices. No build times, no installation.
                            We take the time to test on all major devices to ensure an intuitive, consistent, and optimized experience.</p>
                        <div class="flex wrap gap-20 justify-center w-100">
                            <img class="tplogo"src="windows.png" alt="Windows Logo">
                            <img class="tplogo" src="apple.svg" alt="Apple Logo">
                            <img class="tplogo" src="android.png" alt="Android Logo">
                            <img class="tplogo" src="meta.png" alt="Meta Logo">
                            <img class="tplogo"src="vive.png" alt="Vive Logo">
                            <img class="tplogo" src="winmr.png" alt="Windows Mixed Reality Logo">
                            <img class="tplogo" src="chrome.png" alt="Chrome Logo">
                            <img class="tplogo"src="edge.png" alt="Edge Logo">
                            <img class="tplogo" src="safari.png" alt="Safari Logo">
                            <img class="tplogo" src="firefox.png" alt="Firefox Logo">
                        </div>
                    </div>
                </Expander>
                <Expander expanded={expanded === 3} on:expand={() => onExpand(3)} on:collapse={onCollapse}>
                    <p slot="header" class="bold">Beautiful 3D Models</p>
                    <div slot="content" class="flex w-100 align-center" class:wrap={$smallScreen}>
                        <img class="p-20 noshrink" class:w-50={!$smallScreen} src="doc-beautiful-3d-models.png" alt="Beautiful 3D Models">
                        <p>Virtual Maker is <a href="/blog?p=sketchfab">integrated with Sketchfab</a>, giving you access to millions of models instantly. Take advantage of one of our prebuilt environments to quickly stand up your experience.</p>
                    </div>
                </Expander>
                <Expander expanded={expanded === 4} on:expand={() => onExpand(4)} on:collapse={onCollapse}>
                    <p slot="header" class="bold">Super Fast Iteration, Deployment, and Distribution</p>
                    <div slot="content" class="flex w-100 align-center" class:wrap={$smallScreen}>
                        <img class="p-20 noshrink" style="min-width: 70%" class:w-50={!$smallScreen} src="doc-fast-iteration.png" alt="Fast Iteration">
                        <div>
                            <p>Virtual Maker runs fully in the browser, so users never need to install anything. We take care of hosting, optimizing, and distributing your experience. Just click a link or enter a unique code to jump into any experience.</p>
                            <p>With no build times, deployment steps, or installation. you can iterate and update your experience on every device by just refreshing the browser page.</p>
                        </div>
                    </div>
                </Expander>
                <Expander expanded={expanded === 5} on:expand={() => onExpand(5)} on:collapse={onCollapse}>
                    <p slot="header" class="bold">Stay Up to Date</p>
                    <p slot="content">We regularly update our player to support new devices and interaction models. With all updates, we do the hard work to ensure experiences will continue to function correctly on new hardware.</p>
                </Expander>
            </div>
        </section>

        <section id="tutorials" class="grad" style="background-color: white">
            <div class:pt-60={!$smallScreen} class:pb-120={!$smallScreen} class="content col align-center" style="background-color: transparent">
                <p class:section-title={!$smallScreen} class:section-title-small={$smallScreen} style="color: rgb(40, 101, 158);">LEARN FROM SAMPLES AND TUTORIALS</p>

                <div class="flex w-100 wrapped gap-20 mt-20 justify-center" class:wrap={$smallScreen}>
                    <a href="/blog?p=virtual-tour" class="flex col align-center card relative p-0" style="width: 400px; height: 500px;">
                        <img src="tour2.png" alt="Virtual Tour">
                        <div class="w-100 absolute card-text">
                            <p class="p-20 text-center sz-20">Tutorial: 360 Virtual Tour of Rome</p>
                        </div>
                    </a>
                    <a href="https://www.youtube.com/watch?v=EwsRAP6QpJs" target="_blank" class="flex col align-center card relative p-0" style="width: 400px; height: 500px;">
                        <img src="artgallery.png" alt="VR Art Gallery">
                        <div class="w-100 absolute card-text" style="bottom: 0px">
                            <p class="p-20 text-center sz-20">Tutorial: VR Art Gallery</p>
                        </div>
                    </a>
                    <a href="https://www.youtube.com/watch?v=Wi2D_xSKu2M" target="_blank" class="flex col align-center card relative p-0" style="width: 400px; height: 500px;">
                        <img src="easter.png" alt="VR Egg Hunt">
                        <div class="w-100 absolute card-text" style="bottom: 0px">
                            <p class="p-20 text-center sz-20">Tutorial: VR Egg Hunt</p>
                        </div>
                    </a>
                </div>
                <a href="/demos" class="primary-button action-button" style="width: 220px; margin-top: 120px; text-decoration: none">More Demos</a>
            </div>
        </section>
        <section id="contact" class="part1" style="background-image: linear-gradient(130deg, rgba(64, 64, 64, 0.53) 25%, rgba(73, 0, 119, 0.56) 100%); color: white;">
            <div class:pt-60={!$smallScreen} class:pb-120={!$smallScreen} class="content col align-center" style="background: transparent" >
                <p class:section-title={!$smallScreen} class:section-title-small={$smallScreen}>JOIN THE COMMUNITY</p>
                <div class="flex col">
                    <p>We're working hard to make sure Virtual Maker strikes balance between simple and powerful. But we can't do it alone!</p>
                    <p>Community ideas, feature requests, and bug reports are critical to guiding our development. Join us on Twitter and Discord and be part of the conversation.</p>
                    <Social />
                </div>
            </div>
        </section>

        <section id="faq" class="grad" style="background-color: #EEEEEE66;">
            <div class:pt-60={!$smallScreen} class:pb-120={!$smallScreen} class="content col" style="background: transparent">
                <p class:section-title={!$smallScreen} class:section-title-small={$smallScreen}>FREQUENTLY ASKED QUESTIONS</p>
                <Expander expanded={faqExpanded === 0} on:expand={() => onExpandFaq(0)} on:collapse={onCollapseFaq}>
                    <p slot="header">Do I need programming skills?</p>
                    <div slot="content">
                        <p>No! Designing and publishing your scene requires no coding. You can use actions and built-in components to implement simple interactions, such as moving from scene to scene or moving things around when a button is clicked.</p>
                        <p>But if you do have programming skills, you can use our JavaScript API to enhance your experience in unlimited ways.</p>
                    </div>
                </Expander>
                <!-- <Expander expanded={faqExpanded === 1} on:expand={() => onExpandFaq(1)} on:collapse={onCollapseFaq}>
                    <p slot="header">Can I host my own projects?</p>
                    <p slot="content">Yes, but it is not yet an automated process. Please <a href="#contact">get in touch with us</a> to export your project.</p>
                </Expander> -->
                <!-- <Expander expanded={faqExpanded === 2} on:expand={() => onExpandFaq(2)} on:collapse={onCollapseFaq}>
                    <p slot="header">How much does it cost?</p>
                    <p slot="content">Visit our <a href="/pricing">pricing</a> page.</p>
                </Expander> -->
                <Expander expanded={faqExpanded === 3} on:expand={() => onExpandFaq(3)} on:collapse={onCollapseFaq}>
                    <p slot="header" class="text-left">Will this work on my device/browser?</p>
                    <div slot="content">
                        <p>The Virtual Maker <b>editor</b> works on PC and Mac with all major browsers.</p>
                        <p>The Virtual Maker <b>player</b> should work in any browser that <a href="https://caniuse.com/webgl2" target="_blank">supports WebGL 2.0</a>, which is most modern devices and browsers.</p>
                        <p>Virtual Maker will automatically detect the input capabilities of your browser. For example, if your browser supports virtual reality hand tracking, we’ll use it! If it doesn’t, we’ll fall back to whatever form of input your device supports.</p>
                        <p>For AR/VR experiences, Virtual Maker uses WebXR, so <a href="https://caniuse.com/?search=webxr" target="_blank"> check if your device supports WebXR</a>.</p>
                        <p>Something not working? <a href="#contact">Let us know!</a></p>
                    </div>
                </Expander>
                <Expander expanded={faqExpanded === 4} on:expand={() => onExpandFaq(4)} on:collapse={onCollapseFaq}>
                    <p slot="header">Does this support 360 photos?</p>
                    <div slot="content">
                        <p>Yes, learn how <a href="/docs?p=environments">in the docs</a>.</p>
                    </div>
                </Expander>
                <Expander expanded={faqExpanded === 5} on:expand={() => onExpandFaq(5)} on:collapse={onCollapseFaq}>
                    <p slot="header">Can you build something for us?</p>
                    <p slot="content">Yes! We are looking for interesting projects to demonstrate the capabilities of Virtual Maker. Send us an email at <a href="mailto:support@virtualmaker.net">support@virtualmaker.net</a> and with details about your project and we’ll get back to you quickly.</p>
                </Expander>
                <!-- <Expander expanded={faqExpanded === 6} on:expand={() => onExpandFaq(6)} on:collapse={onCollapseFaq}>
                    <p slot="header">Does it work offline?</p>
                    <p slot="content">Yes, but it is not yet an automated process. Please <a href="#contact">get in touch with us</a> to export your project.</p>
                </Expander> -->
                <Expander expanded={faqExpanded === 7} on:expand={() => onExpandFaq(7)} on:collapse={onCollapseFaq}>
                    <p slot="header">My question is not in this list!</p>
                    <p slot="content">See the <a href="#contact">Join the Community</a> section for different ways to get in touch with us and ask your questions!</p>
                </Expander>
            </div>
        </section>
        <section id="studio" class="grad" style="background-color: #0c0c0c; color: white">
            <div class:pt-60={!$smallScreen} class:pb-120={!$smallScreen} class="content col align-center" style="background-color: transparent">
                <p class:section-title={!$smallScreen} class:section-title-small={$smallScreen}>VIRTUAL MAKER STUDIO</p>
                <div class="flex max-w-[1000px] w-full gap-10 items-start" class:wrap={$smallScreen}>
                    <External target="virtualmakerdev" source="footer" href={studioUrl}>
                        <img src="/virtual-maker-studio-social.png" alt="Virtual Maker Studio" class="p-20 noshrink">
                    </External>
                    <div class="col gap-5 text-white text-2xl">
                        <p>Virtual Maker is a small studio that offers products and services that help innovators bring their products to life.</p>
                        <p>We specialize in Unity, AR/VR and Generative AI.</p>
                        <p>Learn more at <span style="color: #21a6f0;"><External target="virtualmakerdev" source="footer2" href={studioUrl}>virtualmaker.dev</External></span></p>
                    </div>
                </div>
            </div>
        </section>
    </div>
</Page>

<style>
    * {
        font-family: 'Lato', sans-serif;
    }

    section {
        min-height: 100px;
        width: 100%;
        display: inline-flex;
        justify-content: center;
        line-height: 1.5;
    }

    a {
        text-decoration: underline;
    }

    p {
        padding-left: 10px;
        padding-right: 10px;
        padding-top: 5px;
        padding-bottom: 5px;
    }

    a:hover {
        text-decoration: none;
        color: #21a6f0;
    }

    /* a {
        margin-left: 20px;
        margin-right: 20px;
        color: inherit;
        text-decoration: none;
    }

    a:hover {
        color: #21A6F0;
    } */

    img {
        object-fit: contain;
        max-width: 100%;
        min-width: 40px;
    }

    video {
        object-fit: contain;
        max-width: 100%;
        min-width: 100px;
    }

    .content {
        display: inline-flex;
        max-width: 1350px;
        width: 100%;
        margin-bottom: 40px;
        animation-duration: 1s;
        animation-name: slidein;
        animation-play-state: paused;
        background-color: #EEEEEE66;
        border-radius: 20px;
    }

    .card {
        background-color: #21a6f0;
        filter: drop-shadow(15px 15px 9px #A2ADDB66);
        border-radius: 20px;
        transition: all 0.2s ease-in-out;
    }

    .card img {
        border-radius: 20px;
        width: 100%;
        object-fit: cover;
        height: 100%;
    }

    .card:hover {
        transform: scale(1.02);
        padding: 5px;
    }

    .card-text {
        bottom: 0px;
        background-color: #00003388;
        color: white;
        border-bottom-left-radius: 20px;
        border-bottom-right-radius: 20px;
    }

    @keyframes slidein {
        from {
            margin-left: -200px;
            opacity: 0;
        }

        to {
            margin-left: 0px;
            opacity: 1;
        }
    }

    b {
        font-weight: bold;
    }

    .section-title {
        align-self: center;
        margin: 30px;
        margin-bottom: 60px;
        font-size: 2em;
        font-weight: bold;
    }

    .section-title-small {
        align-self: center;
        font-size: 1.5em;
        font-weight: bold;
        padding-top: 1em;
        margin-top: 0.5em;
        margin-bottom: 0.5em;
    }

    /* #editor-img {
        width: 80%;
        border-radius: 10px;
    } */

    /* #signup-bottom {
        height: 300px;
    } */

    .action-button {
        font-size: 1.1em;
        margin-top: 1em;
        padding-top: 0.5em;
        padding-bottom: 0.5em;
        padding-left: 1em;
        padding-right: 1em;
        color: white;
        filter: drop-shadow(5px 5px 4px #2D3E8666);
        transition: all 0.2s ease-in-out;
    }

    .action-button:hover {
        transform: scale(1.1);
        color: white;
    }

    .grad {
        background: transparent;
    }

    .tplogo {
        width: 75px;
        height: 75px;
        object-fit: contain;
    }
</style>