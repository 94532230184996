import { BlobDownloadResponseParsed, ContainerClient } from "@azure/storage-blob";
import api from './api.js';
import type { AssetSource } from "../models/assetInfo.js";
import { isNode } from '../util/util.js';
import user from "./user.js";

export interface Store {
    list(prefix?: string): Promise<string[]>;
    save(data: Blob, name: string): Promise<string>;
    copy(from: string, to: string): Promise<string>;
    load(name: string): Promise<Blob>;
    delete(name: string): Promise<void>;
    getUrl(name: string): string;
}

export class AzureStore implements Store {
    private containerClient: ContainerClient;
    private connecting: Promise<void>;
    private connectResolve: () => void;

    constructor(url?: string) {
        this.connecting = new Promise<void>(resolve => this.connectResolve = resolve);
        if (url) {
            this.connect(url);
        }
    }

    protected connect(url: string) {
        this.containerClient = new ContainerClient(url);
        this.connectResolve();
    }

    public async list(prefix?: string) {
        await this.connecting;
        const blobsIter = this.containerClient.listBlobsFlat({ prefix });
        const blobItems = [];
        try {
            for await (const blob of blobsIter) {
                blobItems.push(blob);
            }
        } catch (e) {
            console.error("Failed to list: " + prefix);
            throw e;
        }

        blobItems.sort((b1, b2) => b1.properties.lastModified.valueOf() - b2.properties.lastModified.valueOf());
        return blobItems.map(b => b.name);
    }

    public async save(data: Blob, name: string) {
        await this.connecting;
        const blockBlobClient = this.containerClient.getBlockBlobClient(name);

        try {
            await blockBlobClient.uploadData(data);
        } catch (e) {
            console.error("Failed to save: " + name);
            throw e;
        }

        return blockBlobClient.url;
    }

    public async copy(from: string, to: string) {
        await this.connecting;
        const fromBlockBlobClient = this.containerClient.getBlockBlobClient(from);
        const toBlockBlobClient = this.containerClient.getBlockBlobClient(to);

        try {
            await toBlockBlobClient.syncCopyFromURL(fromBlockBlobClient.url);
        } catch (e) {
            console.error(e);
            throw e;
        }

        return toBlockBlobClient.url;
    }

    public async load(name: string) {
        await this.connecting;
        const blockBlobClient = this.containerClient.getBlockBlobClient(name);
        let result: BlobDownloadResponseParsed;
        try {
            result = await blockBlobClient.download();
        } catch (e) {
            // Try again -- for some reason auth fails if we go too fast
            try {
                result = await blockBlobClient.download();
            } catch (e) {
                console.error("Failed to load: " + name);
                throw e;
            }
        }

        return result.blobBody;
    }

    public async delete(name: string) {
        await this.connecting;
        const blockBlobClient = this.containerClient.getBlockBlobClient(name);
        try {
            await blockBlobClient.deleteIfExists();
        } catch (e) {
            console.error("Failed to delete: " + name);
            console.error(e);
        }
    }

    public getUrl(name: string) {
        const blobClient = this.containerClient.getBlockBlobClient(name);
        return blobClient.url;
    }
}

export class UserStore extends AzureStore {
    constructor() {
        super();
        this.connect();
    }

    protected async connect() {
        const container = await api.getContainer();
        super.connect(container);
    }
}

export class TrialStore extends AzureStore {
    constructor() {
        super();
        this.connect();
    }

    protected async connect() {
        const container = await api.getTrialContainer(user.getOrCreateTrialId());
        super.connect(container);
    }
}

export class PublicStore extends AzureStore {
    constructor(path: string) {
        super(process.env.AZURITE ?
            `http://127.0.0.1:10000/devstoreaccount1/${path}` :
            `https://360tourdata.blob.core.windows.net/${path}`);
    }

    public async save(data: Blob, name: string): Promise<string> {
        throw new Error("Not allowed to save to public store");
    }

    public async copy(from: string, to: string): Promise<string> {
        throw new Error("Not allowed to copy in public store");
    }

    public async delete(name: string) {
        throw new Error("Not allowed to delete in public store");
    }
}

let _mediaStore: Store;
let _publicStore: Store;
let _userStore: Store;


if (!isNode()) {
    _mediaStore = new PublicStore('media');
    _publicStore = new PublicStore('public');
}

export function setUserStore(store: Store) {
    _userStore = store;
    userStore = _userStore;
}

export const mediaStore = _mediaStore;
export const publicStore = _publicStore;
export let userStore = _userStore;

export function getAssetUrl(assetSource: AssetSource) {
    if (assetSource.url.startsWith('http')) {
        return assetSource.url;
    }

    return assetSource.provider === 'user' ? userStore.getUrl(assetSource.url) : mediaStore.getUrl(assetSource.url);
}

export function getAssetInfoUrl(assetSource: AssetSource) {
    const infoName = 'info-' + assetSource.url.slice(assetSource.url.indexOf('-') + 1, assetSource.url.lastIndexOf('.')) + '.json';
    return getAssetUrl({ provider: assetSource.provider, url: infoName });
}