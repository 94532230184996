<script lang="ts">
    import { createEventDispatcher } from 'svelte';
    import { slide } from 'svelte/transition';

    export let expanded = false;

    const dispatch = createEventDispatcher();

    function onClick() {
        expanded = !expanded;

        if (expanded) {
            dispatch('expand');
        } else {
            dispatch('collapse');
        }
    }
</script>

<div class="flex col w-100 align-start">
    <button class="flex gap-10 align-center font-inherit" on:click={onClick}>
        {expanded ? "▲" : "▼"}<slot name="header" />
    </button>
    {#if expanded}
        <div transition:slide>
            <slot name="content"  />
        </div>
    {/if}
</div>

<style>
    button {
        background-color: #00446622;
        margin-bottom: 5px;
        padding-left: 10px;
        width: 100%;
        border-radius: 10px;
    }

    button:hover {
        background-color: #00446633;
    }
</style>

